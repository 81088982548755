import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout, {
  Player,
  ViewButtonNext,
  ViewButtonPrevious,
  ShareButtons,
  ViewModuleName,
  ViewChoice,
  ViewImgTop,
  Breadcrumbs,
} from '../components/layout';
import SEO from '../components/seo';
import Sound from '../audio/fr/voixoff_FR_8.mp3';
import Background from '../images/landscape-2.jpg';
import Borne1 from '../images/Bornes-1.png';
import Part1Videos from '../components/videos/part-1-videos.fr';

const ViewArticle = styled.article`
  display: flex;
  flex: 1 0 auto;
  flex-flow: column nowrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  overflow-y: auto;
  max-width: 100%;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;

  > * {
    display: flex;
  }
`;

const Part1_Videos = () => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO
      pathname="/fr/part-1-videos"
      title={
        'Découvre l’application FinDiETou et les vidéos du module “Gérer les revenus de mon activité”'
      }
      description="Bienvenue dans la Partie 1 qui t’apprendra à Gérer les revenus de ton activité. Elle te donnera des conseils pour comprendre et bien gérer tes revenus, tes dépenses, et tes bénéfices. Bonne formation !"
      quote="Bienvenue dans la Partie 1 qui t’apprendra à Gérer les revenus de ton activité. Elle te donnera des conseils pour comprendre et bien gérer tes revenus, tes dépenses, et tes bénéfices. Bonne formation !"
      hashtag="#FinDiETou"
      banner="https://i.postimg.cc/yYYvm9Mp/module1.jpg"
    />

    <ViewArticle>
      <Part1Videos />
      <ViewModuleName>
        <ViewChoice>
          <ViewImgTop src={Borne1} />
          Gérer les revenus de mon activité
        </ViewChoice>
      </ViewModuleName>
    </ViewArticle>
    <Player sound={Sound} />

    <Link to="/fr/part-1-astuces">
      <ViewButtonPrevious color="warning">
        <i className="fa fa-chevron-left fa" />
      </ViewButtonPrevious>
    </Link>
    <Link to="/fr/part-1-evaluation">
      <ViewButtonNext color="warning">
        <i className="fa fa-chevron-right" />
      </ViewButtonNext>
    </Link>
    <ShareButtons />
    <Breadcrumbs active={5} />
  </Layout>
);

export default Part1_Videos;
